<script>
// wow.js
const WOW = require('wow.js');
import 'animate.css';

// vue components
import gotop from './components/Gotop.vue';
import aco from './components/Aco.vue';
import modal from './components/modal.vue';

export default {
    inject: ["mq"],

    components: {
        gotop,
        aco,
        modal,
    },

    data() {
        return {
            headerH: 0, //ヘッダーの高さ
            gnavActive: false, //グロナビアクティブ管理
        }
    },

    methods: {
        // wow.js初期化
        wowInit() {
            const wow = new WOW({
                offset: this.mq.mdMinus ? 100 : 300, //SP : PC
                animateClass: 'animate__animated'
            });
            wow.init();
        },

        // ヘッダーの高さを取得
        getHeaderH() {
            const header = this.$refs.header;

            const func = () => {
                this.headerH = header.getBoundingClientRect().height;
            }

            func();
        },

        scrollToSection() {
            // URLからクエリパラメータを取得
            const params = new URLSearchParams(window.location.search);
            const newsletter = params.get('newsletter');
            const schedule = params.get('schedule');

            // newsletterまたはscheduleパラメータがある場合、その値に基づいてスクロール
            let targetId = '';
            if (newsletter) {
                targetId = "newsletter";
            } else if (schedule) {
                targetId = "schedule";
            }

            if (targetId) {
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                // 要素が見つかった場合、headerH分の高さを加味してその位置にスクロール
                const yOffset = -this.headerH;
                const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                }
            }
        }
    },

    mounted() {
        this.wowInit();
        this.getHeaderH();
        this.scrollToSection();
        console.log(this.headerH);
    },

}
</script>