<!-- =====================================================================================

Components: ポップアップ

====================================================================================== -->
<script>
export default {
  data() {
    return {
      isModalOpen: false,
      modalIndex: null,
      titles: ["高炉", "コークス炉", "転炉", "連続鋳造設備", "セメントロータリーキルン", "ガラス窯", "ごみ処理炉", "電気炉"],
    };
  },
  methods: {
    openModal(index) {
      this.modalIndex = index;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
};
</script>

<template>
    <transition name="popup-reference-fade">
              <div
          v-show="isModalOpen"
          :class="{
            popup: true,
            'is-open': isModalOpen,
          }"
        >
        <div class="overlay" @click="closeModal"></div>
        <div class="main">
          <button class="close-button" @click="closeModal"></button>
          <figure class="use__modal"><img :src="`/assets/img/refractories/use_modal0${modalIndex + 1}.webp`" width="500" height="460" alt=""></figure>
          <p class="use__modal-txt">{{ titles[modalIndex] }}</p>
        </div>
      </div>
    </transition>

      <li class="use__item" v-for="(title, index) in titles" :key="index">
        <button class="open-button" @click="openModal(index)">
          <figure class="use__icon"><img :src="`/assets/img/refractories/use_icon0${index + 1}.webp`" width="106.5" height="106.5" alt=""></figure>
          <p class="use__name">{{ title }}</p>
          <figure class="use__bg"><img :src="`/assets/img/refractories/use0${index + 1}.webp`" width="394" height="232" alt=""></figure>
        </button>
      </li>

</template>